<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="10">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm id="loginForm" v-show="showLogin">
                  <h1>Login</h1>
                  <CAlert
                    :show.sync="showError"
                    closeButton
                    color="danger"
                  >
                   {{ errorMessage }}
                  </CAlert> 
                  <CAlert
                    :show.sync="showSuccess"
                    closeButton
                    color="success"
                  >
                   {{ successMessage }}
                  </CAlert>                                      
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    id="username"
                    placeholder="Username"
                    autocomplete="username"
                    description="Please enter your username."                
                    :invalid-feedback=invalidFeedbackUsername
                    v-model="username"
                    required
                    :is-valid="!$v.username.$invalid"                    
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>

                  <CInput
                    id="password"
                    placeholder="Password"
                    :type=passwordFieldType
                    autocomplete="curent-password"
                    description="Please enter your password."
                    required
                    v-model="password"
                    :is-valid="!$v.password.$invalid"
                    v-on:keyup.enter="login"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                    <template #append>
                      <CButton v-on:click="switchVisibility()" style="padding-bottom: 0em;" color="light">
                        <span class="material-icons md-18">{{ visibility }}</span>
                      </CButton>
                    </template>                    
  
                  </CInput>
                  <CRow>
                    
                    <CCol col="6" class="text-left">
                      <CButton v-on:keyup.enter="submit"  v-on:click="login()" id="login" color="primary" class="px-4">Login</CButton>
                      
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" v-on:click="openContent('forgotPassword')" class="px-0">Forgot password?</CButton>
                    </CCol>
                  </CRow>
                </CForm>
                <CForm id="newPassword" v-show="newPassword">
                  <h1>Change password</h1>
                  <CAlert
                    :show.sync="showError"
                    closeButton
                    color="danger"
                  >
                   {{ errorMessage }}
                  </CAlert>   
                  <p class="text-muted">Please set a new password!</p>

                  
                  <CInput
                    placeholder="Verfification Code"
                    type="text"
                    v-model="passwordCode"
                    description="Please enter you password reset code."
                    :invalid-feedback=invalidFeedbackPasswordCode
                    required
                    :is-valid="!$v.passwordCode.$invalid"      
                    v-show="newPasswordCode"                                
                  >
                    <template #prepend-content><CIcon name="cil-lock-unlocked"/></template>                                      
                  </CInput>

                  <CInput
                    placeholder="Password"
                    :type=passwordFieldType
                    v-model="password"
                    :invalid-feedback=invalidFeedbackPassword
                    required
                    :is-valid="!$v.password.$invalid"                    
                    
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                    <template #append>
                      <CButton v-on:click="switchVisibility()" style="padding-bottom: 0em;" color="light">
                        <span class="material-icons md-18">{{ visibility }}</span>
                      </CButton>
                    </template>                                        
                  </CInput>

                  <CInput
                    placeholder="Repeat Password"
                    :type=passwordFieldType                  
                    :invalid-feedback=invalidFeedbackRepeatPassword
                    v-model="repeatPassword"
                    :is-valid="!$v.repeatPassword.$invalid"                    
                    v-on:keyup.enter="changePassword"
                    required
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                    <template #append>
                      <CButton v-on:click="switchVisibility()" style="padding-bottom: 0em;" color="light">
                        <span class="material-icons md-18">{{ visibility }}</span>
                      </CButton>
                    </template>                    
                      
                  </CInput>                  
                  <CRow>
                    <CCol>
                      <CButton  v-on:click="changePassword()" color="primary" class="float-left px-4" :disabled='($v.password.$invalid || $v.repeatPassword.$invalid)'>Change</CButton>
                      <CButton  v-on:click="openContent('login')" color="light" class="float-right px-4">Back</CButton>

                    </CCol>
                  </CRow>
                </CForm>       
                <CForm id="forgotPassword" v-show="forgotPassword">
                  <h1>Forgot password</h1>
                  <CAlert
                    :show.sync="showError"
                    closeButton
                    color="danger"
                  >
                   {{ errorMessage }}
                  </CAlert>   
                  <p class="text-muted">Please enter your username to request a new password!</p>

                  <CInput
                    placeholder="Username"
                    autocomplete="username"
                    description="Please enter your username."                
                    :invalid-feedback=invalidFeedbackUsername
                    v-model="username"
                    required
                    :is-valid="!$v.username.$invalid"
                    v-on:keyup.enter="callForgotPassword"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                 
                  <CRow>
                    <CCol>
                      <CButton  v-on:click="callForgotPassword()" color="primary" class="float-left px-4" :disabled='$v.username.$invalid'>Request</CButton>
                      <CButton  v-on:click="openContent('login')"  color="light" class="float-right px-4">Back</CButton>
                    </CCol>
                  </CRow>
                </CForm>                             
              </CCardBody>
            </CCard>

            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
           
                <h2>About</h2>
                <p>Welcome to the Well-Architected Review Report Tool. Here you can manage, create and generate Well-Architected Reports. </p>
                <p>This a product by DoiT International. </p>
              </CCardBody>
              <CCardFooter v-bind:style="{ backgroundColor: 'white'}">
                
                <img
                  src="@/assets/img/logo.png"
                  height="24" 
                  alt="Logo"
                >                                    
              </CCardFooter>              
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { required, sameAs, minLength, maxLength, integer, helpers } from 'vuelidate/lib/validators'
const passwordRegexp = helpers.regex('passwordRegexp', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&"§%\\\/()\[\]{}+#-~=´`:;'.-_])[A-Za-z\d$!%*?&"§%\\\/()\[\]{}+#-~=´`:;'.-_]{8,}$/);
// const passwordRegexp = helpers.regex('passwordRegexp', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
// 
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      passwordCode: '',
      repeatPassword: '',
      invalidFeedbackUsername: "",
      invalidFeedbackPassword: "",
      invalidFeedbackRepeatPassword: "",
      showError: false,
      errorMessage: "",
      loading: false,
      showLogin: true,
      newPassword: false,
      newPasswordCode: false,
      forgotPassword: false,
      visibility: "visibility",
      passwordFieldType: "password",
      showSuccess: false,
      successMessage: "",
      invalidFeedbackPasswordCode: '',
    }
  },
  methods: {

    switchVisibility() {
      if (this.passwordFieldType === 'password') {
        this.passwordFieldType = "text"
        this.visibility = "visibility_off"
      } else {
        this.passwordFieldType = "password"
        this.visibility = "visibility"
      }
    },
    openContent(content) {
      switch (content) {
        case "login":
          this.showLogin = true;
          this.newPassword = false;
          this.newPasswordCode = false;    
          this.forgotPassword = false;            
          break;
        case "forgotPassword":
          this.showLogin = false;
          this.newPassword = false;
          this.newPasswordCode = false;    
          this.forgotPassword = true;
          break;

        case "changePassword":
          this.showLogin = false;
          this.newPassword = true;
          this.newPasswordCode = false;    
          this.forgotPassword = false;
          break;
        case "changePasswordCode":
          this.showLogin = false;
          this.newPassword = true;
          this.newPasswordCode = true;    
          this.forgotPassword = false;
          break;          
        default:
          this.showLogin = true;
          this.newPassword = false;
          this.newPasswordCode = false;    
          this.forgotPassword = false; 
          break;
      }
    },
    backToLogin() {
      this.openContent("login")  
    },
    callForgotPassword() {
      this.$cognitoAuth.forgotPassword(this.username, (err, result) => { 
        if (err) {
          console.log(err)
          console.log(result)
          this.openContent('login');

        } else {
          this.password = '';
          this.repeatPassword = '';
          this.openContent('changePasswordCode');
        }

      })
    },
    login () {
      this.$v.$touch()
      if (this.$v.password.$invalid || this.$v.username.$invalid) {
        this.showError = true;
        this.errorMessage = "Username / Password invalid!"
      } else {      
        this.loading = true
        /*eslint: no-unused-vars: "off"*/
        this.$cognitoAuth.authenticate(this.username, this.password, (err, result) => {

            if (err && result) {
                this.showError = true;
                this.errorMessage = "Username or password not correct!"
                this.loading = false;
            } 
            else if (err && !result) 
            {
              this.password = '';
              this.openContent('changePassword');        
            } else {              
                localStorage.setItem('token', result.idToken.jwtToken);
                this.$router.push(this.$route.query.redirect || '/');
            }
        });
      }
    },
    changePassword() {

      if (!this.newPasswordCode) {
        this.$cognitoAuth.handleNewPassword(this.repeatPassword, (err, result) => {

        if (err) {
          // console.log(err);
          // console.log(result);
          this.showError = true;
          // this.errorMessage = err;
          this.errorMessage = "Couldn't change the password!"
        } else {              
          this.password = '';
          this.openContent('login');

          this.successMessage = "Password changed successfully!";
          this.showSuccess = true;
        }

        });
      } else {
        this.$cognitoAuth.changePassword(this.username, this.repeatPassword, this.passwordCode, (err, result) => {
        if (err) {
          this.showError = true;
          this.errorMessage = result.message
        } else {              
          this.password = '';
          this.openContent('login');
          this.successMessage = "Password changed successfully!";
          this.showSuccess = true;
        }

        });
      }
    }
  },
  validations: {
    username: {
      required,
      minLength: minLength(4)
      
    },    
    password: {
      required,
      minLength: minLength(8),
      passwordRegexp
    },
    repeatPassword: {
      required,
      passwordRegexp,
      minLength: minLength(8),
      sameAsPassword: sameAs('password')
    },
    passwordCode: {
      required,
      integer,
      minLength: minLength(6),
      maxLength: maxLength(6)
    }
  },
  watch: {
    password: function(val) {
        if (!this.$v.password.required) {
          this.invalidFeedbackPassword = "Password is required."
        } else if (!this.$v.password.minLength) {
          this.invalidFeedbackPassword = "Password must have at least " + this.$v.password.$params.minLength.min +" letters, numbers, lower and uppercase letters and one special character."
        }     
    },
    repeatPassword: function(val) {
        if (!this.$v.repeatPassword.sameAsPassword) {
          this.invalidFeedbackRepeatPassword = "Passwords must be identical."
        }
    },
    passwordCode: function(val) {
        if (!this.$v.passwordCode.invalid) {
          this.invalidFeedbackPasswordCode = "Please provide a valid password reset code."
        }
    }        
  }  
}
</script>
